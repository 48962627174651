/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Box,
} from "@mui/material";
import moment from "moment";
import { IconEdit } from "@tabler/icons";
import LoaderContext from "contexts/LoaderContext";
import { useRestoreDevice } from "services/DeviceService";
import {
  useNavigate,
} from "../../node_modules/react-router-dom/dist/index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import TableSortLabel from "@mui/material/TableSortLabel";

const StyledTableCell = styled(TableCell)(({ theme, isActive }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.colors.primaryMain,
    color: theme.palette.common.white,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function UserDetail({ value, index, user }) {
  const [router, setRouter] = useState({});
  const { updateIsLoadingStatus } = useContext(LoaderContext);
  const [currentDevice, setCurrentDevice] = useState(null);
  const navigate = useNavigate();
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    message: "",
    level: "success",
  });
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("lastTimeOnlineAt");

  const {
    data: useRestoreDeviceData,
    loading: useRestoreDeviceLoading,
    status: useRestoreDeviceStatus,
    request: useRestoreDeviceRequest,
    source: useRestoreDeviceSource,
  } = useRestoreDevice(user?.id, router?.id, currentDevice?.macAddress);

  useEffect(() => {
    updateIsLoadingStatus(useRestoreDeviceLoading);
  }, [useRestoreDeviceLoading]);

  useEffect(() => {
    if (user?.routers) setRouter(user.routers[0]);
  }, [user?.routers]);

  useEffect(() => {
    if (currentDevice) useRestoreDeviceRequest();
  }, [currentDevice]);

  useEffect(() => {
    if (!useRestoreDeviceStatus) return;
    if (useRestoreDeviceStatus === 200 || useRestoreDeviceStatus === 204) {
      setSnackAlert({
        open: true,
        message: "Dispositívo restaurado com sucesso!",
        level: "success",
      });

      return navigate(`/users/${user?.id}/device/${currentDevice?.id}`);
    }

    setSnackAlert({
      open: true,
      message: "Falha ao restaurar o dispositivo!",
      level: "error",
    });
    setCurrentDevice(null);
  }, [useRestoreDeviceStatus]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const activeTableHead = {
    "& .css-vs7gox-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active": {
      color: "#fff",
    },
  };

  //Filtragem da tabela
  const sortedDevices = [...(router?.devices || [])].sort((a, b) => {
    // ordenação por Id
    if (orderBy === "id") {
      return order === "asc" ? a.id - b.id : b.id - a.id;
    }
    // ordenação por Nome
    if (orderBy === "name") {
      return order === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    // ordenação por Pessoas
    if (orderBy === "people") {
      const peopleCountA = a.people?.length || 0;
      const peopleCountB = b.people?.length || 0;

      if (peopleCountA === 0 && peopleCountB > 0) {
        return 1;
      }
      if (peopleCountA > 0 && peopleCountB === 0) {
        return -1;
      }
      if (peopleCountA !== peopleCountB) {
        return order === "asc"
          ? peopleCountA - peopleCountB
          : peopleCountB - peopleCountA;
      }

      const firstPersonNameA = (a.people?.[0]?.name || "").toLowerCase();
      const firstPersonNameB = (b.people?.[0]?.name || "").toLowerCase();
      return order === "asc"
        ? firstPersonNameA.localeCompare(firstPersonNameB)
        : firstPersonNameB.localeCompare(firstPersonNameA);
    }
    // ordenação por Comodo
    if (orderBy === "room") {
      return order === "asc"
        ? a.room.name.localeCompare(b.room.name)
        : b.room.name.localeCompare(a.room.name);
    }
    // ordenação por registrado
    if (orderBy === "registeredAt") {
      const registeredA = a.registeredAt ? 1 : 0;
      const registeredB = b.registeredAt ? 1 : 0;
      return order === "asc"
        ? registeredA - registeredB
        : registeredB - registeredA;
    }
    // ordenação por Bloqueado
    if (orderBy === "isBlocked") {
      const isBlockedA = a.isBlocked ? 1 : 0;
      const isBlockedB = b.isBlocked ? 1 : 0;
      return order === "asc"
        ? isBlockedA - isBlockedB
        : isBlockedB - isBlockedA;
    }
    // ordenação por Online
    if (orderBy === "lastTimeOnlineAt") {
      const dateA = a.lastTimeOnlineAt
        ? new Date(a.lastTimeOnlineAt).getTime()
        : 0;
      const dateB = b.lastTimeOnlineAt
        ? new Date(b.lastTimeOnlineAt).getTime()
        : 0;
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    // ordenação por Data de exclusão
    if (orderBy === "deletedAt") {
      const dateA = a.deletedAt ? new Date(a.deletedAt).getTime() : 0;
      const dateB = b.deletedAt ? new Date(b.deletedAt).getTime() : 0;
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  const tableRow = [
    { key: "id", name: "ID" },
    { key: "name", name: "Nome" },
    { key: "ipMac", name: "IP/MAC" },
    { key: "people", name: "Pessoas" },
    { key: "room", name: "Comodos" },
    { key: "registeredAt", name: "Registrado" },
    { key: "isBlocked", name: "Bloqueado" },
    { key: "lastTimeOnlineAt", name: "Online" },
    { key: "deletedAt", name: "Data de exclusão" },
  ];

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackAlert.open}
        autoHideDuration={3000}
        onClose={() => setSnackAlert({ ...snackAlert, open: false })}
      >
        <MuiAlert
          variant="filled"
          onClose={() => setSnackAlert({ ...snackAlert, open: false })}
          severity={snackAlert.level}
          sx={{ width: "100%" }}
        >
          {snackAlert.message || ""}
        </MuiAlert>
      </Snackbar>
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {tableRow.map((row) => (
                    <StyledTableCell
                      sortDirection={orderBy === row.key ? order : false}
                      align="left"
                      sx={activeTableHead}
                    >
                      <TableSortLabel
                        active={orderBy === row.key}
                        direction={orderBy === row.key ? order : "asc"}
                        onClick={() => handleRequestSort(row.key)}
                      >
                        {row.name}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                  <StyledTableCell
                    align="left"
                  ></StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedDevices.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.id}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.name}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.ip}
                      <br />
                      {row.macAddress}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.people?.length ? (
                        <ul style={{ padding: 0, paddingLeft: 10, margin: 0 }}>
                          {row.people.map((person) => (
                            <li key={person.name}>{person.name}</li>
                          ))}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.room?.name || "-"}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.registeredAt ? "SIM" : "NÃO"}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.isBlocked ? "SIM" : "NÃO"}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.lastTimeOnlineAt
                        ? moment(row.lastTimeOnlineAt).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell style={{ opacity: row.deletedAt ? 0.4 : 1 }}>
                      {row.deletedAt
                        ? moment(row.deletedAt).format("DD/MM/YYYY HH:mm:ss")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {!row.deletedAt && (
                        <Button
                          variant="text"
                          onClick={() => {
                            if (router.isOnline === true) {
                              navigate(`/users/${user.id}/device/${row.id}`);
                            } else {
                              setSnackAlert({
                                open: true,
                                message: "Mikrotik do usuário desconectada",
                                level: "error",
                              });
                            }
                          }}
                        >
                          <IconEdit />
                        </Button>
                      )}
                      {row.deletedAt && (
                        <Button
                          variant="contained"
                          disableElevation
                          style={{ fontWeight: "bolder" }}
                          onClick={() => {
                            if (router.isOnline === true) {
                              const confirmresult = window.confirm(
                                "Deseja restaurar este dispositivo?"
                              );
                              if (confirmresult) setCurrentDevice(row);
                            } else {
                              setSnackAlert({
                                open: true,
                                message: "Mikrotik do usuário desconectada",
                                level: "error",
                              });
                            }
                          }}
                        >
                          RESTAURAR
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
}
