/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Button,
  Box,
  Container as MaterialContainer,
} from "@mui/material";
import moment from "moment";
import Container from "components/Container";
import { useGetUsers } from "services/UserService";
import { Link } from "react-router-dom";
import { IconEdit, IconAlarmPlus } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "../../node_modules/@mui/material/index";
import MuiAlert from "@mui/material/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.colors.primaryMain,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function UserDetail({ value, index, user }) {
  const navigate = useNavigate();

  const [router, setRouter] = useState({});
  const [snackAlert, setSnackAlert] = useState({
    open: false,
    message: "",
    level: "success",
  });

  useEffect(() => {
    if (user?.routers) setRouter(user.routers[0]);
  }, [user?.routers]);

  const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];

  const routineTypes = {
    0: "Interromper conexão dos aparelhos por período",
    1: "Interromper conexão de forma intermitente",
    2: "Tempo de uso",
  };

  function minuteToTime(minute) {
    return `${Math.trunc(minute / 60).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    })}:${Math.trunc(Math.abs(minute) % 60).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    })}`;
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackAlert.open}
        autoHideDuration={3000}
        onClose={() => setSnackAlert({ ...snackAlert, open: false })}
      >
        <MuiAlert
          variant="filled"
          onClose={() => setSnackAlert({ ...snackAlert, open: false })}
          severity={snackAlert.level}
          sx={{ width: "100%" }}
        >
          {snackAlert.message || ""}
        </MuiAlert>
      </Snackbar>
      {value === index && (
        <Box sx={{ pt: 3, textAlign: "right" }}>
          <Button
            variant="text"
            onClick={() => {
              if (router.isOnline === true) {
                navigate(`/users/${user?.id}/routine`);
              } else {
                setSnackAlert({
                  open: true,
                  message: "Mikrotik do usuário desconectada",
                  level: "error",
                });
              }
            }}
            sx={{ mb: "10px" }}
          >
            ADICIONAR <IconAlarmPlus style={{ marginLeft: "15px" }} />
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">ID</StyledTableCell>
                  <StyledTableCell align="left">Nome</StyledTableCell>
                  <StyledTableCell align="left">Tipo</StyledTableCell>
                  <StyledTableCell align="left">Dispositivo</StyledTableCell>
                  <StyledTableCell align="left">Pessoa</StyledTableCell>
                  <StyledTableCell align="left">Comodo</StyledTableCell>
                  <StyledTableCell align="left">Dias</StyledTableCell>
                  <StyledTableCell align="left">Inicio/Fim</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {router?.routines?.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {row.id}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {row.name}{" "}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {routineTypes[row.type] || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {" "}
                      {row.device?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {" "}
                      {row.people?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {row.room?.name || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {!row.disconnectDaysOfWeek?.length ? (
                        "-"
                      ) : (
                        <ul style={{ padding: 0, paddingLeft: 10, margin: 0 }}>
                          {row.disconnectDaysOfWeek.map((v) => <li key={v}>{weekDays[v]}</li>)}
                        </ul>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      {row.disconnectTimeUsage ? (
                        <>{minuteToTime(row.disconnectTimeUsage)}h</>
                      ) : (
                        <>
                          {row.disconnectStartTime}h<br />
                          {row.disconnectEndTime}h
                        </>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ verticalAlign: "top" }}>
                      <Button
                        variant="text"
                        onClick={() => {
                          if (router.isOnline === true) {
                            navigate(`/users/${user.id}/routine/${row.id}`);
                          } else {
                            setSnackAlert({
                              open: true,
                              message: "Mikrotik do usuário desconectada",
                              level: "error",
                            });
                          }
                        }}
                        sx={{ mb: "10px" }}
                      >
                        <IconEdit />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </div>
  );
}
